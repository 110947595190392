import { useEffect, useState } from 'react';
import { RedocStandalone } from 'redoc';

import {
  fetchBrandingConfig,
  fetchBrandingEnv,
  fetchSwaggerApi,
} from '../../api';

import { StyledLoader } from './Redoc.styled';
import { DEFAULT_THEME } from './constants';
import { RunConfigProps, SwaggerDataProps } from './types';

export const Redoc = () => {
  const [runConfig, setRunConfig] = useState<RunConfigProps>();
  const [swaggerData, setSwaggerData] = useState<SwaggerDataProps>();

  const swaggerApi = runConfig?.apis?.SWAGGER_API;

  const getBrandingConfig = async (url: string) => {
    try {
      const data = await fetchBrandingConfig(url);
      const themeData = data?.runcfg;

      if (themeData) {
        setRunConfig(themeData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getBrandingEnv = async () => {
    try {
      const data = await fetchBrandingEnv();
      const brandingUrl = data?.brandingUrl;

      if (brandingUrl) {
        getBrandingConfig(brandingUrl);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSwaggerData = async (api: string) => {
    try {
      const data = await fetchSwaggerApi(api);

      if (data) {
        setSwaggerData(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getBrandingEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swaggerApi) {
      getSwaggerData(swaggerApi);
    }
  }, [swaggerApi]);

  const renderSwagger = () => {
    const brandingTheme = runConfig?.theme?.light;

    if (swaggerData && runConfig) {
      const dataWithLogoFromBranding = {
        ...swaggerData,
        info: {
          ...swaggerData?.info,
          'x-logo': {
            url: runConfig?.logo,
          },
        },
      };

      if (Object.keys(brandingTheme).length !== 0) {
        return (
          <RedocStandalone
            options={{
              theme: brandingTheme,
            }}
            spec={dataWithLogoFromBranding}
          />
        );
      }

      return (
        <RedocStandalone
          options={{
            theme: DEFAULT_THEME,
          }}
          spec={dataWithLogoFromBranding}
        />
      );
    }

    return <StyledLoader>Loading configuration...</StyledLoader>;
  };

  return renderSwagger();
};

import axios from 'axios';

import { apiRequest } from '../../utils';

import { BRANDING_SERVICE_ENV_API } from './endpoints';

export const fetchBrandingConfig = async (url: string) => {
  const { data } = await apiRequest({
    url,
  });

  return data;
};

export const fetchBrandingEnv = async () => {
  const url =
    window?.location?.hostname === 'localhost'
      ? BRANDING_SERVICE_ENV_API
      : `/branding-env.config.json`;

  const { data } = await axios({
    url,
  });

  return data;
};

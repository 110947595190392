export const DEFAULT_THEME = {
  spacing: {
    unit: 50,
    sectionHorizontal: 25,
    sectionVertical: 5,
  },
  breakpoints: {
    small: '10px',
    medium: '20px',
    large: '30px',
  },
  colors: {
    tonalOffset: 1,
    primary: {
      main: '#09d674',
      light: 'blue',
      dark: 'brown',
      contrastText: 'yellow',
    },
    success: {
      main: 'green',
      light: '#189118',
      dark: '#033403',
      contrastText: '#83b583',
    },
    warning: {
      main: '#ea7b19',
      light: '#dc985d',
      dark: '#522b0a',
      contrastText: '#d0ae91',
    },
    error: {
      main: '#d41f1c',
      light: '#d3403d',
      dark: '#4e1b1a',
      contrastText: '#eb9694',
    },
    gray: {
      50: '#777',
      100: '#777',
    },
    border: {
      light: '#9fb4be',
      dark: '#658594',
    },
    text: {
      primary: '#333333',
      secondary: '#878181',
    },
    responses: {
      success: {
        color: 'green',
        backgroundColor: 'rgba(0,128,0,0.07)',
        tabTextColor: 'green',
      },
      error: {
        color: 'red',
        backgroundColor: 'rgba(212,31,28,0.07)',
        tabTextColor: 'red',
      },
      redirect: {
        color: 'gray',
        backgroundColor: 'white',
        tabTextColor: 'gray',
      },
      info: {
        color: 'gray',
        backgroundColor: 'white',
        tabTextColor: 'gray',
      },
    },
    http: {
      get: '#3A9601',
      post: '#0065FB',
      put: '#93527B',
      options: '#947014',
      patch: '#0065FB',
      delete: '#C83637',
      basic: '#707070',
      link: '#07818F',
      head: '#A23DAD',
    },
  },
  schema: {
    linesColor: 'gray',
    defaultDetailsWidth: '70%',
    typeNameColor: 'gray',
    typeTitleColor: '#FF0000',
    requireLabelColor: '#FF0000',
    labelsTextSize: '0.9em',
    nestingSpacing: '1em',
    nestedBackground: 'white',
    arrow: {
      size: '1em',
      color: 'red',
    },
  },
  typography: {
    fontSize: '16px',
    lineHeight: '1.5em',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightBold: '600',
    fontFamily: 'Montserrat,sans-serif',
    smoothing: 'antialiased',
    code: {
      fontSize: '13px',
      fontWeight: '400',
      fontFamily: 'Source Code Pro, monospace',
      color: '#e53935',
      backgroundColor: 'rgba(38, 50, 56, 0.05)',
    },
    headings: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: '700',
      lineHeight: '1.6em',
    },
    links: {
      color: 'blue',
      textDecoration: 'auto',
      hoverTextDecoration: 'auto',
    },
  },
  sidebar: {
    width: '285px',
    backgroundColor: '#fff',
    textColor: 'gray',
    activeTextColor: 'green',
    groupItems: {
      activeBackgroundColor: '#14df8982',
      activeTextColor: 'green',
      textTransform: 'green',
    },
    level1Items: {
      activeBackgroundColor: '#14df8982',
      activeTextColor: 'green',
      textTransform: 'green',
    },
    arrow: {
      size: '23px',
      color: 'gray',
    },
  },
  logo: {
    maxHeight: '100px',
    maxWidth: '100px',
    gutter: '10px',
  },
  rightPanel: {
    backgroundColor: '#1F2933',
    textColor: '#ffffff',
    width: '40%',
    servers: {
      overlay: {
        backgroundColor: '#1F2933',
        textColor: '#ffffff',
      },
      url: {
        backgroundColor: 'green',
      },
    },
  },
  codeBlock: {
    backgroundColor: 'black',
  },
  fab: {
    backgroundColor: '#f2f2f2',
    color: '#0065FB',
  },
};
